
body{
    transition: background-color 1000ms;
    
}
.header{
    font-family:Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: space-between;
    z-index: 10;
    color: white;
    position: sticky;
    background-color: rgb(14,157,205);
    height: 55px;
    padding-left: 10px;
    top: 0px;
    border-bottom: 1px solid #2c2b2b;
    box-shadow: 0px 1px 2px black;
    background-image: linear-gradient(140deg, #2c2b2b 0%, rgb(14,157,205) 50%, #2c2b2b 75%);;
}
.headerlogo{
    font-size: 40px;
    line-height: 55px;
    cursor: pointer;
    color: white;
    text-decoration: none;
}
.headerlink{
    margin: 10px;
    color: white;
    text-decoration: none;
}



.menu {
    list-style-type: none;
    padding: 0;
    
}
.menu.open {
    display: flex;
    height: 200vh;
    overflow: hidden;
    opacity: 1;
    pointer-events: all;
  }

.menu a:hover{
    color: white;
}

.menu li {
    display: inline-block;
    margin: 0px 10px;
    position: relative; /* Add relative positioning to the list items */
}

/* Style the links */
.menu a {
    text-decoration: none;
    color: rgb(206, 206, 206);
    font-weight: bold;
    font-size: 16px;
    transition-duration: 0.4s;
}

/* Create the underline effect on hover */
.menu a::before {
    content: "";
    position: absolute;
    width: 0; /* Start with a width of 0 */
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transition: width 0.3s ease; /* Animate the width */
}

.menu li:hover a::before {
    width: 100%; /* Expand the width to 100% on hover */
}

/* used for the current page effect*/

.menu li:hover a::before,
.menu li a.active::before {
    width: 100%; /* Expand the width to 100% on hover and for the active page */
}

.menu li a.active {
    color: white; /* Set the text color to white for the active page */
}

.menu li a.active::before {
    left: 0; /* Reset the left position for the active page */
}

.hamburger {
    display: none;
    font-size: 30px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }


  @media (max-width: 768px) {
    .headerlogo{
        font-size: 30px;
        line-height: 55px;

    }
    .menu {
        transition: 0.5s ease;
        height: 0px;
        pointer-events: none;
      display: flex;
      opacity: 0;
      flex-direction: column;
      position: fixed;
      top: 55px;
      right: 0;
      background-color: #000000;
      background-image: linear-gradient(to top right, #000000,#2c2b2b );
      width: 100%;
      padding: 5px;
      text-align: center;
      overflow: none;
      z-index: 20;
    }

    .menu ul{
        list-style: none; /* Removes the default bullets */
        padding-left: 0;  /* Removes the left padding */
        margin-left: 0;   /* Removes any left margin */
    }


    .menu li {
        margin: 0px 0px;
        display: block;
        margin-top: 40px;
        text-align: center;
      }
      
      .menu li a {
        color: white;
        text-decoration: none;
        font-size: 30px;
        padding-top: 30px;
        margin: 20px;
      }
      
      .menu li a.active {
        border-bottom: 0px solid white;
      }






      .menu li:hover a::before,
      .menu li a.active::before {
          width: 0%; /* Expand the width to 100% on hover and for the active page */
      }
  

    .hamburger {
      display: block;
    }
  }

.footer{
    background-color: rgb(14,157,205);
    z-index: 9;
    position: relative;
    padding: 20px;
    background-image: linear-gradient(to bottom right, rgb(14,157,205),#2c2b2b );

}
.footerlogo{
    color: white;
    font-size: 30px;
    text-decoration: none;
    margin: 10px;
}
.footerlink{
    margin: 10px;
    color: white;
    text-decoration: none;
}
.footerseperate{
    display: flex;
    justify-content: space-between;
}
@media (max-width: 768px) {
    .footer{
        align-items: center;
        text-align: center;
    }
    .footerlogo{
        margin: 0px;
    }
    .footersection{
        margin: auto;
        width: 80vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .footerseperate{
        display: block;
    }
}

.UISeperator{
    margin-top: 100px;
    margin-bottom: 50px;
    height: 2px;
    width: 80%;
    background: linear-gradient(to left, aquamarine, mediumpurple);
    margin-left: 10%;
    margin-right: 10%;
}
.miniUISeperator{
    margin-top: 50px;
    margin-bottom: 50px;
    height: 2px;
    width: 100px;
    background: linear-gradient(to left, aquamarine, mediumpurple);
    margin: auto;
}
@media (max-width: 768px) {
    .UISeperator{
        margin-top: 50px;
        margin-bottom: 25px;
        height: 2px;
        width: 80%;
        background: linear-gradient(to left, aquamarine, mediumpurple);
        margin-left: 10%;
        margin-right: 10%;
    }
    .miniUISeperator{
        margin-top: 50px;
        margin-bottom: 50px;
        height: 2px;
        width: 100px;
        background: linear-gradient(to left, aquamarine, mediumpurple);
        margin: auto;
    }
}


.homepage{
    background-color: rgb(0, 0, 0);
    color: white;
    font-family:Arial, Helvetica, sans-serif;
    text-align: center;
    z-index: 1;
    position: relative;
    overflow-x: hidden;
}

.homepage h1{
    font-size: 77px;
    font-weight: bold;

}

.homepagetext{
    margin-left: 100px;
    margin-right: 100px;
    line-height: 35px;
}

body{
    padding: none;
    margin: none;
    overflow: visible;
}
@media (max-width: 768px) {
    .homepage h1{
        font-size: 24px;

    }
    .homepage h2{
        font-size: 20px;
    }
    .homepage h3{
        font-size: 15px;
    }
    .homepagetext{
        margin-left: 20px;
        margin-right: 20px;
        line-height: 30px;

    }
}
.inputBox{
    border: 1px solid white;
    background-color: black;
    color: white;
    height: 60px;
    width: 650px;
    margin: auto;
    padding: 5px;
    font-size: 16px;
    border-radius: 5px;
}
.inputBox input{
    background-color: black;
    color: white;
    border: none;
    display: inline;
    width: 330px;
    height: 60px;
    outline: none;
    font-size: 16px;


}

.inputBox button{
    background-color:  rgb(14,157,205);
    border: none;
    display: inline;
    width: 310px;
    height: 60px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
}

@media (max-width: 768px) {
    .inputBox{
        border: none;
        margin: auto;
        padding: 5px;
        font-size: 16px;
        display: flex;
        margin: auto;
        width: 100vw;
        margin-bottom: 80px;
        justify-content: center;
        flex-wrap: wrap;

    }
    .inputBox input{
        border: 1px solid white;
        background-color: black;
        border-radius: 5px;

        color: white;
        display: block;
        width: 90vw;
        height: 50px;
        outline: none;
        font-size: 16px;
        margin-bottom: 20px;
    
    
    }
    
    .inputBox button{
        background-color:  rgb(14,157,205);
        color: #000000;
        border: none;
        display: block;
        width: 90vw;
        height: 50px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
        line-height: 16px;
    }
    
}

.squareContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    overflow: hidden;
}
.square {
    background-size: cover;
    background-image: url(/public/images/laptop-tech.png);
    margin: auto;
    width: 80%;
    height: 500px;
    border-radius: 20px;
    overflow: hidden;
}
.squareFrame{
    height: 100%;
    width: 100%;
}

@media (max-width: 768px) {
    .squareContainer {
        height: 100vw;
    }
    .square {
        background-size: cover;
        background-image: url(/public/images/laptop-tech.png);
        width: 80vw;
        height: 80vw;
        border-radius: 20px;
        overflow: hidden;
    }
}


.sglow{
    text-shadow:
    0 0 5px rgb(14,157,205), 0 0 10px rgb(14,157,205),
    0 0 20px rgb(14,157,205), 0 0 40px rgb(14,157,205);
}
.givingBack{
    padding: 10px;
    background-color: rgb(14,157,205);
    color: rgb(45, 45, 45);
    text-align: center;
    color: dark;
}
.givingBack h2{
    margin: auto;
    width: 50vw;
}
.givingBackContainer{
    display: flex;
    width: 100vw;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-bottom: 50px;
    justify-content: center;
}
.givingbackbuttons{
    cursor: pointer;
    transition-duration: 0.5s;
    margin-bottom: 50px;
    text-decoration: none;
    color: black;
    max-width: 80vw;
}
.givingbackbuttons:hover{
    color: lightgray;
}
@media (max-width: 768px) {
    .givingBack{
        background: linear-gradient(to bottom left,rgb(14,157,205) , mediumpurple);

    }
    .givingBack h2{
        margin: auto;
        width: 80vw;
    }
}
.featurescontainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.minifeaturescontainer{
    display: flex;
    margin: 20px;
}
.featurescard{
    border: 1px solid grey;
    width: 40vw;
    aspect-ratio: 5/3;
    margin: 20px;
    background-color: #00042a;
    min-width: 300px;
    background-image: linear-gradient(to top left, #00042a, #2c2b2b);

}
.featurescardtitle{
    font-size: 35px;
    line-height: 90px;
    display: inline-block;
    margin-left: 30px;
    text-align: left;
    font-weight: bold;
}
.featurescardtext{
    text-align: left;
    padding: 20px;
    margin-left: 30px;
    font-size: 16px;
    line-height: 26px;

}
.featurescardlogo{
    width: 90px;
    height: 90px;
    display:  inline-block;
    border: 2px solid rgb(14,157,205);
    border-radius: 100px;
    background-size: cover;
}

@media (max-width: 768px) {
    .minifeaturescontainer{
        margin: 10px;
    }
    .featurescard{
        border: 1px solid grey;
        width: 80vw;
        aspect-ratio: 5/3;
        margin: 20px;
        background-color: #00042a;
        min-width: 0px;
        background-image: linear-gradient(to bottom right, #00042a, #2c2b2b);
    
    }
    .featurescardtitle{
        font-size: 16px;
        line-height: 40px;
        display: inline-block;
        margin-left: 10px;
        text-align: left;
        font-weight: bold;
    }
    .featurescardtext{
        text-align: left;
        padding: 10px;
        margin-left: 10px;
        font-size: 15px;
        line-height: 20px;
    
    }
    .featurescardlogo{
        width: 40px;
        height: 40px;
        display:  inline-block;
        border: 2px solid rgb(14,157,205);
        border-radius: 100px;
    }
}

.FAQContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    width: 90%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
}

.FAQ-title {
    margin-bottom: 30px;
}

.FAQ-item {
    width: 100%;
    margin-bottom: 20px;
}

.FAQ {
    cursor: pointer;
    font-size: 16px;
    line-height: 30px;
    padding: 15px 20px;
    width: 100%;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    background-color: rgba(27, 27, 27, 0.5);


    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FAQ:hover {
    background-color: rgba(200, 200, 200, 0.25);
    transform: translateY(-2px);
}

.active1 {
    background-color: rgba(200, 200, 200, 0.25);
}

.slide-effect {
    opacity: 0;
    transform: translateX(-100px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.slide-effect.visible {
    opacity: 1;
    transform: translateX(0);
}

.slide-effect.hidden {
    opacity: 0;
    transform: translateX(-100px);
}

.panel {
    text-align: left;
    color: white;
    background-color: rgba(27, 27, 27, 0.5);
    border: none;
    border-top: none;
    width: calc( 100% - 20px);
    padding: 0px 10px;
    max-height: 0;
    overflow: hidden;
    /* transition: max-height 0.1s ease-out, padding 0.1s ease-out; */
    transition-duration: 0.1s;
    border-radius: 5px;
    
}

.panel.active {
    padding: 15px 10px;
    max-height: 1000px; /* Adjust this value based on your content */
    border: 1px solid grey;
}

.FAQ:after {
    content: '\25B6'; /* Right-pointing triangle */
    font-size: 12px;
    color: white;
    transition: transform 0.3s ease;
}

.active1:after {
    transform: rotate(90deg);
}



#blob {
    background-color: white;
    height: 34vmax;
    aspect-ratio: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: linear-gradient(to right, aquamarine, mediumpurple);
    animation: rotate 20s infinite linear;
    opacity: 0.8;
    z-index: -2;
    overflow: visible;

}

#blur {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(12vmax);
    z-index: -1;
    overflow: hidden;
}

@media (max-width: 768px) {
    #blur{
        display: none;

    }
    #blob{
        display: none;
    }
}
.aboutpage{
    background-color: rgb(0, 0, 0);
    color: white;
    font-family:Arial, Helvetica, sans-serif;
    text-align: center;
    z-index: 1;
    position: relative;
    overflow-x: hidden;

}


.aboutpage h1{
    font-size: 77px;
    font-weight: normal;
}
 
.aboutpagetitle{
    font-size: 50px;
}
.aboutpageparagraph{
    padding: 20px;
    font-size: 20px;
    line-height: 40px;
    width: 60vw;
    margin: auto;
}

@media (max-width: 768px) {
    .aboutpage h1{
        font-size: 40px;
        font-weight: normal;
    }
     
    .aboutpagetitle{
        font-size: 30px;
    }
    .aboutpageparagraph{
        padding: 15px;
        font-size: 18px;
        line-height: 30px;
        width: 90vw;
        margin: auto;
    }
}

.pricingpage{
    background-color: rgb(0, 0, 0);
    color: white;
    font-family:Arial, Helvetica, sans-serif;
    text-align: center;
    z-index: 1;
    position: relative;
    overflow-x: hidden;

}


.pricingpage h1{
    font-size: 77px;
    font-weight: normal;
}
@media (max-width: 768px) {
    .pricingpage h1{
        font-size: 30px;
        font-weight: normal;
    }
}

.contact-form-container p{
    width: 80vw;
    text-align: center;
    margin: auto;
    font-size: 12px;
    margin-bottom: 10px;
}


.contactemailsection{
    background-color: rgb(14,157,205);
    background: linear-gradient(to right bottom, rgb(14,157,205), #2c2b2b);

    padding: 10px;
}
.contactemailsection h1{
    font-size: 40px;
}
.contactsocialssection{
    background-color: white;
    color: black;
    margin: none;
    padding: 10px;
}
.contactsocialssection h1{
    font-size: 40px;
}
.socialscontainer{
    display: flex;
    flex-wrap: wrap;
}
.socialsicon{
    width: 30px;
    height: 30px;
    display: inline-block;
    margin: 50px;
    background-size: cover;
}

.select-type {
    /* Styles for the dropdown select */
    max-width: 2000px;
    min-width: 400px;
    width: 80vw;
  }
  
  .input-first-name {
    /* Styles for the first name input */
    display: inline-block;
    max-width: 2000px;
    min-width: 400px;
    width: 38vw;
    margin: 1.5vw;
  }
  
  .input-last-name {
    /* Styles for the last name input */
    display: inline-block;
    max-width: 2000px;
    min-width: 400px;
    width: 38vw;
    margin: 1.5vw;

  }
  
  .textarea-message {
    /* Styles for the message textarea */
    max-width: 2000px;
    min-width: 400px;
    width: 80vw;
    height: 500px;
    font-size: 18px;
    line-height: 30px;
    border-radius: 10px;
    outline: none;
    background-color: rgb(25, 25, 25);
    color: white;
    padding: 10px;
  }
  textarea-message::placeholder {
    color: white; /* Replace with your desired color */
    font-family:Arial, Helvetica, sans-serif;
  }
  
  .submit-btn {
    /* Styles for the submit button */
    margin: auto;
    height: 60px;
    width: 150px;
    font-size: 20px;
    background-color: rgb(14,157,205) ;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    margin-bottom: 50px;
  }

  .form-control{
    border: none;
    border-bottom: 1px solid white;
    background-color: black;
    outline: none;
    color: white;
    height: 50px;
    font-size: 15px;
    line-height: 35px;
  }

  @media (max-width: 768px) {
    .contact-form p{
        font-size: 10px;
    }
    .select-type {
        /* Styles for the dropdown select */
        min-width: 200px;
        width: 80vw;
      }
      
      .input-first-name {
        /* Styles for the first name input */
        min-width: 200px;
        width: 80vw;
      }
      
      .input-last-name {
        /* Styles for the last name input */
        min-width: 200px;
        width: 80vw;    
      }
      
      .textarea-message {
        /* Styles for the message textarea */
        min-width: 200px;
        margin-top: 20px;
        width: 80vw;
        height: 500px;
        font-size: 16px;
        line-height: 30px;
        resize: none;

      }

  }


  .blogmenu{
    background-image: linear-gradient(to bottom right, #000000 0%, #2c2b2b 25%, #000000 75%);;
    color: white;
    font-family:Arial, Helvetica, sans-serif;
    text-align: center;
    z-index: 1;
    position: relative;
    overflow-x: hidden;

}
.blogmenu h1{
    font-size: 77px;
    font-weight: normal;
    text-shadow: 1px 1px 2px black;
}

  .blogcontainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .blogcard{
    width: 30vw;
    margin: 20px;
    text-decoration: none;
    color: white;
    border-radius: 5px;
    aspect-ratio: 3/2;
    background-color: rgb(45, 45, 45);
    transition-duration: 0.3s;
    background-size: cover;    
    box-shadow: 0px 2px 5px rgba(255, 255, 255, 0.2);
    justify-content: last baseline;


    }
    .blogcard:hover{
        box-shadow: 5px 5px 10px gray;

    }

.blogcardbottom{
    border-radius: 5px;
    margin-bottom: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top,rgba(0,0,0,0.8) 15%, transparent 40%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    

}
.blogcardtitle{
    text-decoration: none;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
    font-size: 20px;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;

    margin-top: 0px;
}
.blogcarddate{
    margin-bottom: 5px;
    text-decoration: none;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
    font-size: 15px;
    text-align: left;
    padding-left: 20px;
    color: lightgray;
}
.blogcard-link{
    text-decoration: none;
}
@media (max-width: 768px) {
    .blogmenu h1{
        font-size: 30px;
    }
    .blogcard{
        width: 80vw;
        margin: 20px;
        text-decoration: none;
        color: white;
        border-radius: 5px;
        background-color: rgb(25, 25, 25);
        transition-duration: 0.3s;
    }
    .blogcardtitle{
        text-decoration: none;
        font-size: 14px;
    }
    .blogcarddate{
        font-size: 12px;
    }
    .blogcardbottom{
        background: linear-gradient(to top,rgba(0,0,0,0.8) 30%, transparent 60%);

    }
}

.bloggrid{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.blogmain{
    max-width: 800px;
    width: 60vw;
    margin: 50px;
}
.blogside{
    width: 20vw;
    padding-top: 100px;
}
.blogsidetool{
    border: 1px solid white;
    position: sticky;
    top: 100px;
    background-color: rgb(26, 26, 26);
}
.blogsidetool p{
    font-size: 30px;
    text-align: center;
}
.blogsidetool button{
    height: 50px;
    background-color: rgb(14,157,205);
    border: none;
    cursor: pointer;
    border: 1px solid white;
    color: #000000;

}
.blogsidetool input{
    padding-left: 20px;
    background-color: #2c2b2b;
    border: 1px solid white;
    outline: none;
    color: white;
    padding-right: 10px;

}
.blogsideabout{
    border: 1px solid white;
    padding: 10px;
    font-size: 14px;
    line-height: 20px;
}
.blogsideabout h3{
    font-size: 20px;
}
.blogsidesocial{

    justify-content: center;
}
.blogpage{
    background-color: #2c2b2b;
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
}
.blogpage h1{
    font-size: 40px;
}
.blogpage h2{
    font-size: 30px;
    margin-top: 50px
}
.blogpage p{
    font-size: 18px;
    line-height: 35px;
}
.blogpage strong{
    font-weight: 1000;
    text-shadow: 1px 1px 2px black;
}
.blogpage li{
    padding: 5px;
    font-size: 15px;
    line-height: 25px;
}

@media (max-width: 768px) {
    
.bloggrid{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.blogmain{
    max-width: 800px;
    width: 90vw;
    margin: auto;
}
.blogside{
    margin: auto;
    width: 90vw;
    padding-top: 100px;
    margin-bottom: 50px
}



.blogpage{
    background-color: #2c2b2b;
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
}
.blogpage h1{
    font-size: 32px;
}
.blogpage h2{
    font-size: 22px;
    margin-top: 50px
}
.blogpage p{
    font-size: 17px;
    line-height: 28px;
}
.blogpage strong{
    font-weight: 1000;
    text-shadow: 1px 1px 2px black;
}
.blogpage li{
    padding: 5px;
    font-size: 15px;
    line-height: 25px;
}
}

.toolinfopage{
    background-color: #2c2b2b;
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    padding: 50px;
}

.toolinfopage h1{
    font-size: 32px;
}
.toolinfopage h2{
    font-size: 22px;
    margin-top: 50px
}
.toolinfopage p{
    font-size: 17px;
    line-height: 28px;
}
.toolinfopage li{
    padding: 5px;
    font-size: 20px;
    line-height: 25px;
}

.emailinput{
    text-align: center;
}

.loadingscreen{
    height:calc(100vh - 155px);
    text-align: center;
}
.progressBarContainer{
    border: 1px solid black;
    width: 60vw;
    margin: auto;
    height: 20px;
}
.progressBar{
    background-color: #000;
    width: 50%;
    height: 100%;
}
.toolresults{
    padding: 50px;
}
.toolresults h1{
    text-align: center;
}
.toolresults h3{
    text-align: center;
}
.toolresults button{
    margin: auto;
}




.block {
	position: relative;
	margin: 30px auto 0;
	width: 250px;
	height: 70px;
	background: linear-gradient(to left bottom , rgb(14,157,205), #006975);
    color: white;
    text-decoration: none;
    line-height: 70px;
    font-size: 30px;
    border-radius: 10px;    
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 4px 4px 10px rgba(255, 255, 255, 0.15);
}

.block:before, .block:after {
	content: '';
	position: absolute;
	left: -2px;
	top: -2px;
	background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
		#0000ff, #00ff00,#ffff00, #ff0000);
	background-size: 400%;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	z-index: -1;
	animation: steam 20s linear infinite;
}

@keyframes steam {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}

.block:after {
	filter: blur(50px);
}

@media (max-width: 768px) {
    .block{
        display: block;
        border-radius: 0px;    
        margin: 0px auto 0;
        width: 200px;
        height: 50px;
        background: linear-gradient(0deg, #000, #272727);
        color: white;
        text-decoration: none;
        line-height: 50px;
        font-size: 25px;
    }
}