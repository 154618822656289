.game-cards-container {
  --blue-rgb: 92 192 249;
  --green-rgb: 125 161 35;
  --brown-rgb: 127 46 23;
}

.game-cards-wrapper {
  background-color: rgba(0,0,0,0);
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 1000ms;
}

body:has(.game-card[data-color="blue"]:hover) {
  background-color: rgb(92 192 249);
}

body:has(.game-card[data-color="green"]:hover) {
  background-color: rgb(125 161 35);
}

body:has(.game-card[data-color="brown"]:hover) {
  background-color: rgb(127 46 23);
}

#game-cards {
  display: block;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.game-card {
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: scale 100ms;
}

.game-card .game-card-front-image {
  background-size: cover; /* Ensures the image covers the div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  display: block; /* Ensure it's treated like a block-level element */
  object-fit: cover;  position: relative;
  z-index: 2;
}

.game-card .game-card-image {
  width: clamp(300px, 20vw, 500px);
  aspect-ratio: 2 / 3;
  border-radius: clamp(0.5rem, 0.75vw, 2rem);
}

.game-card-faders {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  opacity: 0;
  transition: opacity 1500ms;
  pointer-events: none;
}

.game-card:hover .game-card-faders {
  opacity: 1;
}

.game-card:active {
  scale: 0.98;
}

.game-card-fader {
  position: absolute;
  left: 0px;
  top: 0px;
}

.game-card-fader:nth-child(odd) {
  animation: game-card-fade-left 3s linear infinite;
}

.game-card-fader:nth-child(even) {
  animation: game-card-fade-right 3s linear infinite;
}

.game-card-fader:is(:nth-child(3), :nth-child(4)) {
  animation-delay: 750ms;
}

.game-card-fader:is(:nth-child(5), :nth-child(6)) {
  animation-delay: 1500ms;
}

.game-card-fader:is(:nth-child(7), :nth-child(8)) {
  animation-delay: 2250ms;
}

@media(max-width: 1200px) {
  .game-cards-wrapper {    
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  #game-cards {
    flex-direction: column; 
    align-items: center;
    gap: 4rem;
    padding: 4rem;
  }
  
  .game-card .game-card-image {
    width: 400px;    
  }
}

@media(max-width: 600px) {
  #game-cards {
    gap: 2rem;
    padding: 2rem;
  }
  
  .game-card {
    width: 80%;    
  }
  
  .game-card .game-card-image {
    width: 100%;    
  }
}

@keyframes game-card-fade-left {
  from {
    scale: 1;
    translate: 0%;
    opacity: 1;
  }
  
  to {
    scale: 0.8;
    translate: -30%;
    opacity: 0;
  }
}

@keyframes game-card-fade-right {
  from {
    scale: 1;
    translate: 0%;
    opacity: 1;
  }
  
  to {
    scale: 0.8;
    translate: 30%;
    opacity: 0;
  }
}

